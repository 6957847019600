<template>
  <div class="install-pwa">
    <div class="close">
      <span class="close-icon" @click="close">
        <i class="fa-solid fa-xmark-circle"></i>
      </span>
    </div>
    <div class="install-container">
      <div class="install-pwa-text">
        <span>{{ $t(isIos ? "install_pwa_ios" : "install_pwa") }}</span>
      </div>
      <div class="install-pwa-button" v-if="!isIos">
        <button @click="installPwa">
          <i class="fa-solid fa-download"></i>
        </button>
      </div>
      <div>
        <span></span>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { ApplicationUtils } from "@/utils/ApplicationUtils";

export default defineComponent({
  name: "InstallPwaComponent",
  data() {
    return {
      isIos: /iphone|ipad|ipod/.test(window.navigator.userAgent.toLowerCase()),
    };
  },
  methods: {
    close() {
      ApplicationUtils.notifyInstallPwa(false);
    },
    installPwa() {
      ApplicationUtils.promptInstallPwa();
    },
  },
});
</script>

<style scoped lang="less">
div.install-pwa {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  width: 100%;
  height: 70px;
  background-color: white;
  box-shadow: 0 3px 10px 0 rgba(80, 80, 80, 0.8);
  z-index: 9999;

  div.close {
    display: flex;
    width: 15%;
    justify-content: center;
    align-items: center;

    span.close-icon {
      color: black;
      font-size: 1.1em;
      cursor: pointer;
    }
  }

  div.install-container {
    display: flex;
    flex-grow: 1;
    height: 100%;

    div.install-pwa-text {
      display: flex;
      flex-grow: 1;
      justify-content: center;
      align-items: center;

      span {
        color: black;
      }
    }

    div.install-pwa-button {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 20%;
      height: 100%;

      button {
        width: 50px;
        height: 30px;
        background-color: #47ea47;
        border: none;
        color: white;
        font-size: 1em;
        border-radius: 10px;
        cursor: pointer;
      }
    }
  }
}
</style>
