export default {
  "road": {
    "number_nights": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("x")), " nuits"])},
    "complete_itinerary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Itinéraire complet"])}
  },
  "help": {
    "search_question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rechercher une question"])},
    "search_no_result": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun résultat à partir de votre recherche"])},
    "help_section": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aide / Contact"])},
    "share_position": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Partager ma position"])},
    "update_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mettre à jour les données"])},
    "updating_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mise à jour des données"])},
    "share_position_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nous vous remercions de <strong>renseigner les principaux éléments</strong> pour identifier où vous vous trouvez <strong>puis d'appuyer sur le bouton bleu</strong> \"<i>Partager ma position</i>\"."])},
    "share_position_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Préciser ici votre position. Exemple : Nous sommes au chaud dans le Bar-Café des Sports situé place de la Liberté."])}
  },
  "news": {
    "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conseils & récits"])},
    "discover": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Découvrir"])}
  },
  "document": {
    "generic_documents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Documents généraux"])}
  },
  "address": {
    "learn_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En savoir plus"])}
  },
  "day": {
    "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["jour"])},
    "gpx_files": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fichiers GPX"])},
    "rider": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vélocistes"])}
  },
  "travel": {
    "show_altimeter_profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voir le profil altimétrique"])},
    "hide_altimeter_profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masquer le profil altimétrique"])},
    "map_center": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recentrer"])},
    "speed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vitesse"])},
    "elevation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Altitude"])},
    "offline": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mode hors connexion"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le mode hors ligne vous permet de naviguer même sans connexion Internet."])},
      "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chargement des données hors ligne"])},
      "download": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Télécharger la carte"])},
      "maps_already_downloaded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cartes déjà téléchargées"])},
      "delete_downloaded_maps": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimer les cartes téléchargées"])}
    }
  },
  "my_stay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mon séjour"])},
  "secret_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Code secret"])},
  "practical_information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Infos pratiques"])},
  "my_profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mon profil"])},
  "days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["jours"])},
  "easy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Facile"])},
  "leisure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Loisir"])},
  "fitness": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forme"])},
  "expert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Expert"])},
  "adventure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aventure"])},
  "see_route": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voir la carte"])},
  "program_day": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Programme du jour"])},
  "house": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hébergement"])},
  "call": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Téléphoner"])},
  "itinerary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Itinéraire"])},
  "starting_city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ville de départ"])},
  "arrival_city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ville d'arrivée"])},
  "locate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Localiser"])},
  "error_occurred": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Une erreur est survenue"])},
  "internal_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Une erreur interne est survenue"])},
  "unknown_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erreur inconnue, contactez votre conseiller"])},
  "delete_profile_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retirer mon voyage de l'application"])},
  "confirm_profile_deletion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voulez-vous vraiment supprimer toutes vos données ?"])},
  "start": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Démarrer"])},
  "wait_location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En attente de la localisation"])},
  "update_app": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mettre à jour l'application"])},
  "where_dine": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Où diner"])},
  "where_lunch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Où déjeuner"])},
  "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chargement"])},
  "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom"])},
  "internet_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucune connexion"])},
  "retry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez réessayer"])},
  "switch_connection_on": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez activer internet pour récupérer votre voyage"])},
  "travel_recovery": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Récupération du voyage"])},
  "saving_trip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sauvegarde du voyage"])},
  "recovery_gpx_files": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Récupération des fichiers GPX"])},
  "saving_gpx_files": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sauvegarde des fichiers GPX"])},
  "unable_save_gpx_files": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impossible de sauvegarder les fichiers GPX"])},
  "unable_retrieve_gpx_files": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impossible de récuperer les fichiers GPX"])},
  "unable_save_trip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impossible de sauvegarder le voyage"])},
  "heritage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Patrimoine"])},
  "restaurant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Restaurant"])},
  "diners": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diner"])},
  "lunch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Déjeuner"])},
  "store": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Magasin"])},
  "install_pwa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Installer l'application sur votre appareil"])},
  "install_pwa_ios": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Installer l'application sur votre appareil en l'ajoutant à votre page d'accueil"])},
  "install_pwa_ios_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Options"])},
  "documents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Documents"])},
  "complete_itinerary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Itinéraire complet"])},
  "parking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parking"])},
  "where_park": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Où se garer"])},
  "shop_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Boutique"])},
  "faq_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Questions fréquentes"])},
  "position_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Partager ma position"])},
  "documents_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Documents de voyage"])},
  "recits": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Récits"])},
  "offline_mode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mode hors connexion"])},
  "more_informations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plus d'informations"])},
  "download_gpx": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Télécharger le fichier GPX"])}
}